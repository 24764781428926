import Immutable from 'immutable'
import { HYDRATE } from 'next-redux-wrapper'
import * as ActionType from '@/actions/search'

const NO_DATA_COUNT = -1
export const ITEMS_PER_PAGE = 15

export const initialState = Immutable.fromJS({
  _hasInit: false,

  autocomplete: {
    isLoadingPlaces: false,
    isLoading: false,
    restaurants: [],
    keywords: [],
    places: [],
    cities: [],
  },
  cachedInitialQuery: {},
  explore: {
    isLoading: false,
    isLoadingMore: false,
    noMore: false,
    hasAd: false,
    resultCount: NO_DATA_COUNT,
    pageCount: 0,
    data: [],
    errorMessage: '',
    range: null,
    offset: 0,
  },
  recommendList: {
    data: [],
    ads: [],
    isLoading: false,
    keyword: '',
  },
  adRestaurants: [],
  categorySuggestions: [],
  mapGLBound: {
    sw: null,
    ne: null,
  },
})

const REDUCER_NODE = 'search'

export default function search(state = initialState, action) {
  switch (action.type) {
    case HYDRATE:
      if (state.get('_hasInit')) {
        return state
      }
      return state.merge(action.payload.get(REDUCER_NODE)).set('_hasInit', true)
    case ActionType.SET_MAPGL_BOUND:
      return state.set(
        'mapGLBound',
        Immutable.fromJS({
          sw: action.sw,
          ne: action.ne,
        })
      )
    case ActionType.CACHE_INITIAL_QUERY:
      return state.set('cachedInitialQuery', Immutable.fromJS(action.query))

    case ActionType.GET_RECOMMEND_LIST:
      return state.setIn(['recommendList', 'isLoading'], true)

    case ActionType.GET_RECOMMEND_LIST_FAILED:
      return state
        .setIn(['recommendList', 'isLoading'], false)
        .setIn(['recommendList', 'keyword'], '')

    case ActionType.GET_RECOMMEND_LIST_SUCCESS:
      return state
        .setIn(
          ['recommendList', 'data'],
          Immutable.fromJS(action.response.response)
        )
        .setIn(
          ['recommendList', 'ads'],
          Immutable.fromJS(
            !!action.response.info.ads ? action.response.info.ads : []
          )
        )
        .setIn(['recommendList', 'isLoading'], false)

    case ActionType.CLEAR_KEYWORD_SEARCH_AUTOCOMPLETE:
      return state
        .setIn(['autocomplete', 'isLoading'], false)
        .setIn(['autocomplete', 'restaurants'], Immutable.fromJS([]))
        .setIn(['autocomplete', 'keywords'], Immutable.fromJS([]))

    case ActionType.GET_KEYWORD_SEARCH_AUTOCOMPLETE:
      return state.setIn(['autocomplete', 'isLoading'], true)

    case ActionType.GET_KEYWORD_SEARCH_AUTOCOMPLETE_SUCCESS:
      return state
        .setIn(['autocomplete', 'isLoading'], false)
        .setIn(
          ['autocomplete', 'keywords'],
          Immutable.fromJS(action.response.response).toOrderedSet().toList()
        )

    case ActionType.GET_KEYWORD_SEARCH_AUTOCOMPLETE_FAILED:
      return state
        .setIn(['autocomplete', 'isLoading'], false)
        .setIn(['autocomplete', 'keywords'], Immutable.fromJS([]))

    case ActionType.GET_RESTAURANT_SUGGESTIONS:
      return state.setIn(['autocomplete', 'isLoading'], true)

    case ActionType.GET_RESTAURANT_SUGGESTIONS_SUCCESS:
      return state
        .setIn(['autocomplete', 'isLoading'], false)
        .setIn(
          ['autocomplete', 'restaurants'],
          Immutable.fromJS(action.response.response).take(action.limit)
        )

    case ActionType.GET_RESTAURANT_SUGGESTIONS_FAILED:
      return state
        .setIn(['autocomplete', 'isLoading'], false)
        .setIn(['autocomplete', 'restaurants'], Immutable.fromJS([]))

    case ActionType.CLEAR_RESTAURANT_SUGGESTIONS:
      return state.setIn(['autocomplete', 'restaurants'], Immutable.fromJS([]))

    case ActionType.GET_PLACE_AUTOCOMPLETE:
      return state.setIn(['autocomplete', 'isLoadingPlaces'], true)

    case ActionType.GET_PLACE_AUTOCOMPLETE_SUCCESS:
      return state
        .setIn(['autocomplete', 'isLoadingPlaces'], false)
        .setIn(['autocomplete', 'places'], Immutable.fromJS(action.perdictions))

    case ActionType.GET_PLACE_AUTOCOMPLETE_FAILED:
      return state
        .setIn(['autocomplete', 'isLoadingPlaces'], false)
        .setIn(['autocomplete', 'places'], Immutable.fromJS([]))

    case ActionType.GET_CITY_SUGGESTIONS:
      return state.setIn(['autocomplete', 'isLoadingPlaces'], true)

    case ActionType.GET_CITY_SUGGESTIONS_SUCCESS:
      return state.setIn(['autocomplete', 'isLoadingPlaces'], false).setIn(
        ['autocomplete', 'cities'],
        Immutable.fromJS(action.response.response).filter(
          (city) => city.get('locationType') < 3
        )
      )

    case ActionType.GET_CITY_SUGGESTIONS_FAILED:
      return state
        .setIn(['autocomplete', 'isLoadingPlaces'], false)
        .setIn(['autocomplete', 'cities'], Immutable.fromJS([]))

    case ActionType.CLEAR_PLACE_AUTOCOMPLETE:
      return state
        .setIn(['autocomplete', 'isLoadingPlaces'], false)
        .setIn(['autocomplete', 'places'], Immutable.fromJS([]))

    case ActionType.EXPLORE_RESTAURANTS:
      return state
        .setIn(['explore', 'isLoading'], true)
        .setIn(['explore', 'isLoadingMore'], false)
        .setIn(['explore', 'noMore'], false)
        .setIn(['explore', 'range'], action.range || null)
        .setIn(['explore', 'resultCount'], NO_DATA_COUNT)
        .setIn(['explore', 'pageCount'], 0)
        .setIn(['explore', 'offset'], 0)
        .setIn(['explore', 'errorMessage'], '')

    case ActionType.EXPLORE_RESTAURANTS_SUCCESS:
      return state
        .setIn(['explore', 'isLoading'], false)
        .setIn(['explore', 'resultCount'], action.response.info.hits || 0)
        .setIn(['explore', 'hasAd'], action.response.info.hasAd || false)
        .setIn(['explore', 'pageCount'], action.response.info.pages || 0)
        .setIn(['explore', 'data'], Immutable.fromJS(action.response.response))
        .setIn(['explore', 'offset'], ITEMS_PER_PAGE)
        .updateIn(['explore', 'range'], (range) =>
          action.offset > 0 ? range : action.response.info.range || null
        )
        .set(
          'adRestaurants',
          Immutable.fromJS(
            !!action.response.info.ads ? action.response.info.ads : []
          )
        )

    case ActionType.EXPLORE_RESTAURANTS_FAILED:
      return state.setIn(['explore', 'isLoading'], false)

    case ActionType.EXPLORE_MORE_RESTAURANTS:
      return state.setIn(['explore', 'isLoadingMore'], true)

    case ActionType.EXPLORE_MORE_RESTAURANTS_SUCCESS:
      return state
        .setIn(['explore', 'isLoadingMore'], false)
        .setIn(['explore', 'noMore'], action.response.response.length == 0)
        .setIn(
          ['explore', 'offset'],
          state.getIn(['explore', 'offset']) + ITEMS_PER_PAGE
        )
        .updateIn(['explore', 'data'], (data) =>
          data
            .concat(Immutable.fromJS(action.response.response))
            .toOrderedSet()
            .toList()
        )

    case ActionType.EXPLORE_MORE_RESTAURANTS_FAILED:
      return state.setIn(['explore', 'isLoadingMore'], false)

    case ActionType.ON_LOCATION_ERROR:
      return state
        .set('explore', initialState.get('explore'))
        .setIn(['explore', 'errorMessage'], action.errorMessage)

    case ActionType.GET_CATEGORY_SUGGESTIONS_SUCCESS:
      return state.set(
        'categorySuggestions',
        Immutable.fromJS(action.response.response)
      )

    default:
      return state
  }
}
